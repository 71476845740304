import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakti"} />
		<Helmet>
			<title>
				KnowledgeBridge
			</title>
			<meta name={"description"} content={"Мрієте розпочати власну справу, але не знаєте, з чого почати? Чи, можливо, ви хочете вдосконалити свої знання, щоб вивести існуючий бізнес на новий рівень? У KnowledgeBridge ми допомагаємо отримати знання, навички та підтримку, які потрібні для успіху в сучасному світі бізнесу."} />
			<meta property={"og:title"} content={"KnowledgeBridge"} />
			<meta property={"og:description"} content={"Мрієте розпочати власну справу, але не знаєте, з чого почати? Чи, можливо, ви хочете вдосконалити свої знання, щоб вивести існуючий бізнес на новий рівень? У KnowledgeBridge ми допомагаємо отримати знання, навички та підтримку, які потрібні для успіху в сучасному світі бізнесу."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744383f9710450018a4aca9/images/png-clipart-partnership-computer-icons-business-partner-business-partner-service-business-removebg-preview.png?v=2024-11-25T13:01:50.804Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header12 />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uploads.quarkly.io/6744383f9710450018a4aca9/images/stages-of-business-growth.jpg?v=2024-11-25T13:00:36.722Z) center/cover">
			<Override slot="SectionContent" sm-align-items="center" margin="0px 0 0px 0" />
			<Box max-width="760px" padding="50px 80px 80px 50px" background="rgba(255, 255, 255, 0.75)" color="--dark">
				<Text as="h2" font="--headline4" margin="0 0 12px 0">
					<Strong>
						Почніть свою бізнес-подорож з KnowledgeBridge
					</Strong>
				</Text>
				<Text as="h2" font="--base" margin="0 0 12px 0">
					Ми допоможемо вам подолати розрив між ідеями та успіхом. Від старту до масштабування — ви отримаєте всі необхідні знання та підтримку для досягнення ваших бізнес-цілей.
				</Text>
				<Text font="--headline4" text-transform="uppercase">
					0970936624
				</Text>
				<Text font="--headline4" text-transform="uppercase">
					info@knowledgebridge24.live.com
				</Text>
				<Text font="--headline4" text-transform="uppercase">
					бульвар Тараса Шевченка, 9, Тернопіль, Тернопільська область, 46002
				</Text>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link4">
				0970936624
			</Override>
			<Override slot="link5">
				info@knowledgebridge24.live.com
			</Override>
			<Override slot="link6">
				бульвар Тараса Шевченка, 9, Тернопіль, Тернопільська область, 46002
			</Override>
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});